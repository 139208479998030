import { createRouter, createWebHistory } from "vue-router";
import { createAsyncComponent } from "@/utils/asyncComponentLoader";
import { logger } from "@/utils/logger";

// Route components use direct dynamic imports
const EnergyBreakdown = () => import("@/components/EnergyBreakdown.vue");
const CSVConsumer = () => import("@/components/CSVConsumer.vue");
const BillingPeriodCollector = () => import("@/components/BillingPeriodCollector.vue");
const AccountSettings = () => import("@/components/AccountSettings.vue");
const TermsOfService = () => import("@/components/TermsOfService.vue");
const PrivacyPolicy = () => import("@/components/PrivacyPolicy.vue");
const SiteMap = () => import("@/components/AppSitemap.vue");

// Non-route component keeps createAsyncComponent wrapper
const SharePasswordDialog = createAsyncComponent(
  () => import("@/components/SharePasswordDialog.vue"),
  "SharePasswordDialog"
);

import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { fetchData } from "@/services/dataService";
import { createApp } from "vue";

const SHARE_PASSWORD_PREFIX = "share_pwd_";
const SHARED_PREFIX = "shared_";
const DEVICES_KEY = process.env.VUE_APP_DEVICES_KEY;
const UTILITIES_KEY = process.env.VUE_APP_UTILITIES_KEY;
const BILLING_PERIODS_KEY = process.env.VUE_APP_BILLING_PERIODS_PPL_KEY;
const PEOPLE_KEY = process.env.VUE_APP_PEOPLE_KEY;
const SHARE_HISTORY_KEY = process.env.VUE_APP_SHARE_HISTORY_KEY || "powerPilot_shareHistory";

// Define base routes that will be used for both regular and shared paths
const routes = [
  {
    path: "/",
    redirect: "/breakdown",
  },
  {
    path: "/breakdown",
    name: "EnergyBreakdown",
    component: EnergyBreakdown,
  },
  {
    path: "/upload",
    name: "CSVConsumer",
    component: CSVConsumer,
  },
  {
    path: "/billing",
    name: "BillingPeriods",
    component: BillingPeriodCollector,
  },
  {
    path: "/settings",
    name: "Settings",
    component: AccountSettings,
  },
  {
    path: "/terms",
    name: "Terms of Service",
    component: TermsOfService,
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: SiteMap,
  },
  {
    path: "/share/:id/:component?",
    component: () => import("@/components/SharedLayout.vue"),
    props: true,
    beforeEnter: async (to, from, next) => {
      try {
        const data = await fetchData(to.params.id);
        const { encryptedData, lastModified } = data;

        const cookieKey = `${SHARE_PASSWORD_PREFIX}${to.params.id}`;
        let password = Cookies.get(cookieKey);

        // Check if we have a stored lastModified timestamp
        const storedLastModified = localStorage.getItem(`${SHARED_PREFIX}lastModified_${to.params.id}`);
        const needsUpdate = !storedLastModified || new Date(lastModified) > new Date(storedLastModified);

        // If we have a password but need an update, use it directly
        if (password && needsUpdate) {
          try {
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, password).toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);

            localStorage.setItem(SHARED_PREFIX + DEVICES_KEY, JSON.stringify(parsedData.devices));
            localStorage.setItem(SHARED_PREFIX + UTILITIES_KEY, JSON.stringify(parsedData.utilities));
            localStorage.setItem(SHARED_PREFIX + BILLING_PERIODS_KEY, JSON.stringify(parsedData.billingPeriods));
            localStorage.setItem(SHARED_PREFIX + PEOPLE_KEY, JSON.stringify(parsedData.people));
            localStorage.setItem(SHARED_PREFIX + "active", "true");
            localStorage.setItem(`${SHARED_PREFIX}lastModified_${to.params.id}`, lastModified);

            Cookies.set(cookieKey, password);
            const saveToShareHistory = id => {
              const history = JSON.parse(localStorage.getItem(SHARE_HISTORY_KEY) || "[]");
              const existingIndex = history.findIndex(item => item.id === id);

              const historyItem = {
                id,
                lastVisited: new Date().toISOString(),
                title: `Shared ${id}`,
              };

              if (existingIndex !== -1) {
                // Update existing entry
                history[existingIndex] = historyItem;
              } else {
                // Add new entry, limit to last 10 entries
                history.unshift(historyItem);
                if (history.length > 10) history.pop();
              }

              localStorage.setItem(SHARE_HISTORY_KEY, JSON.stringify(history));
            };
            saveToShareHistory(to.params.id);
            next();
            return;
          } catch (error) {
            // If decryption fails with stored password, clear it and continue to password prompt
            Cookies.remove(cookieKey);
            password = null;
          }
        } else if (password && !needsUpdate) {
          // If we have a password and don't need an update, proceed
          next();
          return;
        }

        // If we need a password, show the dialog
        if (!password) {
          const container = document.createElement("div");
          container.style.position = "fixed";
          container.style.zIndex = "9999";
          container.style.top = "0";
          container.style.left = "0";
          container.style.right = "0";
          container.style.bottom = "0";
          document.body.appendChild(container);

          const result = await new Promise(resolve => {
            const dialog = createApp(SharePasswordDialog, {
              onSubmit: pwd => {
                dialog.unmount();
                document.body.removeChild(container);
                resolve(pwd);
              },
              onCancel: () => {
                dialog.unmount();
                document.body.removeChild(container);
                resolve(null);
              },
            });

            dialog.mount(container);
          });

          if (!result) {
            next("/breakdown");
            return;
          }
          password = result;
        }

        try {
          const decryptedData = CryptoJS.AES.decrypt(encryptedData, password).toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);

          localStorage.setItem(SHARED_PREFIX + DEVICES_KEY, JSON.stringify(parsedData.devices));
          localStorage.setItem(SHARED_PREFIX + UTILITIES_KEY, JSON.stringify(parsedData.utilities));
          localStorage.setItem(SHARED_PREFIX + BILLING_PERIODS_KEY, JSON.stringify(parsedData.billingPeriods));
          localStorage.setItem(SHARED_PREFIX + PEOPLE_KEY, JSON.stringify(parsedData.people));
          localStorage.setItem(SHARED_PREFIX + "active", "true");
          localStorage.setItem(`${SHARED_PREFIX}lastModified_${to.params.id}`, lastModified);

          Cookies.set(cookieKey, password);
          const saveToShareHistory = id => {
            const history = JSON.parse(localStorage.getItem(SHARE_HISTORY_KEY) || "[]");
            const existingIndex = history.findIndex(item => item.id === id);

            const historyItem = {
              id,
              lastVisited: new Date().toISOString(),
              title: `Shared ${id}`,
            };

            if (existingIndex !== -1) {
              // Update existing entry
              history[existingIndex] = historyItem;
            } else {
              // Add new entry, limit to last 10 entries
              history.unshift(historyItem);
              if (history.length > 10) history.pop();
            }

            localStorage.setItem(SHARE_HISTORY_KEY, JSON.stringify(history));
          };
          saveToShareHistory(to.params.id);
          next();
        } catch (decryptError) {
          logger.error("Decryption failed:", decryptError);
          Cookies.remove(cookieKey);
          alert("Invalid password or corrupted data");
          next("/breakdown");
        }
      } catch (error) {
        logger.error("Error loading shared data:", error);
        if (error.message.includes("404")) {
          alert("This shared link has expired or does not exist.");
        } else {
          alert("Failed to load shared data. Please try again later.");
        }
        next("/breakdown");
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../components/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global navigation guard to handle shared data prefix
router.beforeEach((to, from, next) => {
  const isSharedRoute = to.path.startsWith("/share/");
  const prefix = isSharedRoute ? SHARED_PREFIX : "";
  window.localStorage_prefix = prefix;
  next();
});

export default router;
