<template>
  <Suspense>
    <template #default>
      <div class="app-container">
        <div ref="mainRef" class="min-h-screen bg-surface-light dark:bg-surface-dark flex">
          <!-- Navigation Sidebar -->
          <AppNavigation
            :class="{
              'fixed md:relative min-h-full flex-shrink-0 flex flex-col bg-surface-light dark:bg-surface-dark border-r border-secondary dark:border-secondary-dark transition-all duration-300 ease-in-out z-50': true,
              'w-16': (isMobile && isPortrait) || isSidebarCollapsed,
              'w-64': !(isMobile && isPortrait) && !isSidebarCollapsed,
              '-translate-x-full': isMobile && isPortrait && !isSidebarOpen,
              'translate-x-0': !(isMobile && isPortrait && !isSidebarOpen),
            }"
            :is-sidebar-open="isSidebarOpen"
            :is-sidebar-collapsed="isSidebarCollapsed"
            :is-mobile="isMobile"
            :is-desktop="isDesktop"
            :is-portrait="isPortrait"
            :nav-items="navItems"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
            @toggle-desktop-sidebar="toggleDesktopSidebar"
            @toggle-mobile-sidebar="toggleMobileSidebar"
          />

          <!-- Right Column Stack -->
          <div class="flex-1 flex flex-col">
            <!-- Header -->
            <AppHeader
              class="bg-surface-light dark:bg-surface-dark border-b border-secondary dark:border-secondary-dark z-40"
              :title="currentPageTitle"
              :subtitle="currentPageSubtitle"
              :is-sidebar-open="isSidebarOpen"
              :is-sidebar-collapsed="isSidebarCollapsed"
              :is-mobile="isMobile"
              :is-desktop="isDesktop"
              :is-portrait="isPortrait"
              @toggle-mobile-sidebar="toggleMobileSidebar"
            >
              <template #actions>
                <component :is="headerActions" v-if="headerActions" />
              </template>
              <template #default>
                <component :is="headerContent" v-if="headerContent" />
              </template>
            </AppHeader>

            <!-- Main Content -->
            <AppPane
              class="bg-surface-light dark:bg-surface-dark shadow-sm p-4 sm:p-6 md:p-8 lg:p-12 xl:p-20 z-30 app-pane"
              :spacing="isDesktop ? 'normal' : 'compact'"
              :is-mobile="isMobile"
              :is-desktop="isDesktop"
              :is-portrait="isPortrait"
              :is-sidebar-collapsed="isSidebarCollapsed"
            />

            <!-- Footer -->
            <AppFooter
              class="bg-surface-light-secondary dark:bg-surface-dark-secondary z-40"
              :is-sidebar-open="isSidebarOpen"
              :is-sidebar-collapsed="isSidebarCollapsed"
              :is-mobile="isMobile"
              :is-desktop="isDesktop"
              :is-portrait="isPortrait"
            />
          </div>
        </div>
        <!-- Mobile sidebar overlay -->
        <div
          v-if="isMobile && isPortrait && isSidebarOpen"
          class="fixed inset-0 bg-black/30 dark:bg-black/50 z-30"
          @click="toggleMobileSidebar"
        ></div>
        <SpeedInsights />
        <Analytics />
      </div>
    </template>
    <template #fallback>
      <div class="min-h-screen flex items-center justify-center">
        <div class="text-content-light-secondary dark:text-content-dark-secondary">Loading...</div>
      </div>
    </template>
  </Suspense>
</template>

<script setup>
import { ref, computed, provide, watch } from "vue";
import { UploadCloud, BarChart, DollarSign, Settings } from "lucide-vue-next";
import { useRoute } from "vue-router";
import useMobileDetect from "@/composables/useMobileDetect";
import { createAsyncComponent } from "@/utils/asyncComponentLoader";
import { SpeedInsights } from "@vercel/speed-insights/vue";
import { Analytics } from "@vercel/analytics/vue";

const AppNavigation = createAsyncComponent(() => import("@/components/AppNavigation.vue"), "AppNavigation");

const AppHeader = createAsyncComponent(() => import("@/components/AppHeader.vue"), "AppHeader");

const AppPane = createAsyncComponent(() => import("@/components/AppPane.vue"), "AppPane");

const AppFooter = createAsyncComponent(() => import("@/components/AppFooter.vue"), "AppFooter");

const mainRef = ref(null);
const isSidebarOpen = ref(false);
const isSidebarCollapsed = ref(false);
const { isMobile, isPortrait } = useMobileDetect();
const isDesktop = computed(() => !isMobile.value);
const touchStartX = ref(0);
const touchEndX = ref(0);

const route = useRoute();

// Get share ID from route if present
const shareId = computed(() => {
  const id = route.params.id;
  return id || false;
});

// Compute current page title based on route
const currentPageTitle = computed(() => {
  const currentRoute = route.path.split("/").pop();
  const navItem = navItems.value.find(item => item.path.endsWith(currentRoute));
  return navItem?.label || "Power Pilot";
});

// Compute current page subtitle based on route
const currentPageSubtitle = computed(() => {
  const currentRoute = route.path.split("/").pop();
  const navItem = navItems.value.find(item => item.path.endsWith(currentRoute));
  return navItem?.sub || "";
});

// Example: For URL http://localhost:8080/share/Okss293c
// shareId will be "Okss293c"

// Modify nav items to include share ID in paths when present
const navItems = computed(() => [
  {
    label: "Energy Breakdown",
    sub: "Navigate your energy usage",
    icon: BarChart,
    path: shareId.value ? `/share/${shareId.value}/breakdown` : "/breakdown",
  },
  {
    label: "Billing Periods",
    sub: "Navigate your billing",
    icon: DollarSign,
    path: shareId.value ? `/share/${shareId.value}/billing` : "/billing",
  },
  {
    label: "Upload CSV",
    sub: "Import data for your utilities and devices",
    icon: UploadCloud,
    path: shareId.value ? `/share/${shareId.value}/upload` : "/upload",
  },
  {
    label: "Settings",
    sub: "Set up and maintain your data",
    icon: Settings,
    path: shareId.value ? `/share/${shareId.value}/settings` : "/settings",
  },
]);

const onTouchStart = event => {
  touchStartX.value = event.touches[0].clientX;
};

const onTouchMove = event => {
  touchEndX.value = event.touches[0].clientX;
};

const onTouchEnd = () => {
  const touchDistance = touchEndX.value - touchStartX.value;
  if (touchDistance > 50) {
    isSidebarOpen.value = true;
  } else if (touchDistance < -50) {
    isSidebarOpen.value = false;
  }
};

const toggleMobileSidebar = () => {
  if (isMobile.value) {
    isSidebarCollapsed.value = !isSidebarCollapsed.value;
    isSidebarOpen.value = !isSidebarOpen.value;
  }
};

const toggleDesktopSidebar = () => {
  if (isDesktop.value) {
    isSidebarCollapsed.value = !isSidebarCollapsed.value;
  }
};

watch(isPortrait, (newValue, oldValue) => {
  if (!newValue && oldValue && isMobile.value) {
    isSidebarOpen.value = false;
  }
});

// Header state management
const headerActions = ref(null);
const headerContent = ref(null);

// Reset header on route changes
watch(
  () => route.path,
  () => {
    headerActions.value = null;
    headerContent.value = null;
  }
);

const setHeaderActions = actions => {
  headerActions.value = actions;
};

const setHeaderContent = content => {
  headerContent.value = content;
};

provide("setHeaderActions", setHeaderActions);
provide("setHeaderContent", setHeaderContent);
</script>
