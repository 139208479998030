/* eslint-disable no-console */
const isDevelopment = process.env.NODE_ENV !== "production";

export const logger = {
  error: (message, ...args) => {
    if (isDevelopment) {
      console.error(message, ...args);
    }
    // Here you could add error reporting service integration
  },

  warn: (message, ...args) => {
    if (isDevelopment) {
      console.warn(message, ...args);
    }
  },

  info: (message, ...args) => {
    if (isDevelopment) {
      console.info(message, ...args);
    }
  },

  debug: (message, ...args) => {
    if (isDevelopment) {
      console.debug(message, ...args);
    }
  },
};
