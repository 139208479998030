import kvStorage from "../utils/kvStorage";
import { logger } from "@/utils/logger";

export async function fetchData(id) {
  try {
    const cleanId = id.replace(/['"]/g, "");
    const decodedId = decodeURIComponent(cleanId);
    logger.debug("Original ID:", id);
    logger.debug("Cleaned ID:", cleanId);
    logger.debug("Decoded ID:", decodedId);

    const pathId = decodedId.includes("/") ? decodedId.split("/").pop().split("?")[0] : decodedId;
    logger.debug("Path ID:", pathId);

    const downloadUrl = await kvStorage.get(pathId);
    if (!downloadUrl) {
      throw new Error("Download URL not found");
    }
    logger.debug("Download URL:", downloadUrl);

    const cleanUrl = decodeURIComponent(downloadUrl.replace(/['"]/g, ""));
    logger.debug("Clean URL:", cleanUrl);

    const response = await fetch(cleanUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    logger.error("Error fetching data:", error);
    throw error;
  }
}
