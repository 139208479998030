// src/composables/useMobileDetect.js
import { ref, onMounted, onUnmounted } from "vue";

export default function useMobileDetect() {
  const isMobile = ref(false);
  const isLandscape = ref(false);
  const isPortrait = ref(true);

  function checkDeviceState() {
    // Check mobile status
    const userAgent = navigator.userAgent;
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    // Check orientation and responsive breakpoints if window exists
    if (typeof window !== "undefined") {
      // Only consider portrait/landscape for actual mobile devices or smaller screens
      if (isMobileDevice || window.innerWidth < 640) {
        isLandscape.value = window.innerWidth > window.innerHeight;
        isPortrait.value = !isLandscape.value;
        isMobile.value = true;
      } else {
        // For larger screens (like 2K), force landscape mode
        isLandscape.value = true;
        isPortrait.value = false;
        isMobile.value = false;
      }
    }
  }

  onMounted(() => {
    checkDeviceState();
    window.addEventListener("resize", checkDeviceState);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkDeviceState);
  });

  return { isMobile, isLandscape, isPortrait };
}
