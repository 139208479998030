import { defineAsyncComponent, h } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { logger } from "@/utils/logger";

const ErrorComponent = {
  name: "AsyncError",
  props: {
    error: {
      type: Error,
      required: true,
    },
    componentName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return () =>
      h(
        "div",
        {
          class: "p-4 bg-red-100 dark:bg-red-900 rounded-lg",
        },
        [
          h("p", { class: "text-red-700 dark:text-red-100 font-medium" }, `Failed to load ${props.componentName}`),
          h("p", { class: "text-red-600 dark:text-red-200 text-sm mt-1" }, props.error.message),
        ]
      );
  },
};

export function createAsyncComponent(loader, componentName) {
  return defineAsyncComponent({
    loader,
    loadingComponent: LoadingSpinner,
    errorComponent: ErrorComponent,
    delay: 200,
    timeout: 30000,
    suspensible: false,
    onError: (error, retry, fail, attempts) => {
      if (attempts <= 3) {
        retry();
      } else {
        logger.error(`Error loading ${componentName}:`, error);
        fail();
      }
    },
  });
}
