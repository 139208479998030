import { logger } from "@/utils/logger";

class KVStorage {
  constructor() {
    this.apiUrl = process.env.VUE_APP_KV_REST_API_URL;
    this.apiToken = process.env.VUE_APP_KV_REST_API_TOKEN;
  }

  async get(key) {
    try {
      const response = await fetch(`${this.apiUrl}/get/${key}`, {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.result;
    } catch (error) {
      logger.error("Error getting from KV:", error);
      throw error;
    }
  }

  async set(key, value) {
    try {
      const response = await fetch(`${this.apiUrl}/set/${key}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      logger.error("Error setting to KV:", error);
      throw error;
    }
  }

  async del(key) {
    try {
      const response = await fetch(`${this.apiUrl}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
        body: JSON.stringify(["DEL", key]),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      logger.error("Error deleting from KV:", error);
      throw error;
    }
  }
}

// Export singleton instance
export default new KVStorage();
